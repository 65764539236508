import App from "./App.svelte";
import { init } from "@sentry/browser";

init({
  dsn:
    "https://f978f7857fb84f338fa9bbd00c4a5152@o436784.ingest.sentry.io/5452689",
  integrations: [],
  tracesSampleRate: 0,
  environment: "production",
  release: "print-export-frontend@1.1.0",
});

const app = new App({
  target: document.body,
  props: {},
});

export default app;
