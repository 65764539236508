<script>
  let back = () => {
    window.history.go(-1);
    return false;
  };
</script>

<style>
  button {
    margin-top: 0.5em;
  }
</style>

<div class="a-header d-flex align-items-center justify-content-between a-px-20">
  <div class="d-flex align-items-center">
    <div class="a-pwc-logo-grid a-md" />
    <span class="divider a-mx-20" />
    <div class="font-weight-medium a-nowrap">Silverfin print export</div>
  </div>
  <div>
    <button
      class="a-btn a-btn-primary a-btn-lg a-btn-gray"
      on:click={back}>Tilbage</button>
  </div>
</div>
