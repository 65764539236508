<script>
  import "../node_modules/appkit/dist/vendor.min.css";
  import "../node_modules/appkit/dist/appkit.min.css";
  import * as Sentry from "@sentry/browser";
  import { onMount } from "svelte";

  import ListItem from "./ListItem.svelte";
  import Alert from "./Alert.svelte";
  import Footer from "./Footer.svelte";
  import Header from "./Header.svelte";

  console.log("Print Export frontend v1.1.0")

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  let companyID = urlParams.get("company_id");
  let periodID = urlParams.get("period_id");

  let data;
  let error = false;

  let getStatus = async (cID, pID) => {
    try {
      const response = await fetch(
        `https://silverfinprintexport.pwc.dk/api/status?company_id=${cID}&period_id=${pID}`
      );

      if (response.status !== 200) {
        error = true;
      } else {
        // Call the .json() method on your response to get your JSON data
        const json = await response.json();
        error = false;
        console.log(json);
        return json;
      }
    } catch (e) {
      error = true;
      Sentry.captureException(e);
    }
  };

  onMount(async () => {
    data = await getStatus(companyID, periodID);

    if (data && data.companyState == "pending") {
      let timerId = setInterval(async () => {
        if (data && data.companyState != "pending") {
          clearInterval(timerId);
        }
        data = await getStatus(companyID, periodID);
      }, 5000);
      setTimeout(() => {
        clearInterval(timerId);
      }, 500000);
    }
  });
</script>

<svelte:head>
  <title>Silverfin Print Export</title>
</svelte:head>

<div d-flex flex-column a-template-container a-bg-content>
  <Header />
  <div class="a-template-content a-bg-content" id="hfn-content-container">
    <div class="text-left d-flex justify-content-center a-p-20">
      <div class="w-50 center">
        <ul class="a-list d-table w-100">
          <li class="a-list-item d-table-row a-bg-f5f">
            <div class="d-table-cell a-px-10 a-text-body a-py-5 l-head">
              NAVN
            </div>
            <div
              class="text-right d-table-cell a-px-10 a-text-body a-py-5 l-head"
            >
              ID
            </div>
            <div
              class="d-table-cell text-right a-px-10 a-text-body a-py-5 l-head"
            >
              STATUS
            </div>
          </li>
          {#if data && data.files !== null}
            {#each data.files as file}
              <ListItem {file} type={data.type} />
            {/each}
          {:else}no items{/if}
        </ul>
      </div>
    </div>
    {#if data && (data.type == 'penneo' || data.type == 'pdf')}
      <div class="text-left d-flex justify-content-center a-p-20">
        <div class="w-50 center text-center">
          {#if data.folderID !== 0 && (data.companyState === "success" || data.companyState === "partial-success")}
            <a
              class="a-btn a-btn-primary a-btn-lg"
              href={"https://pwc-dk-live.getsilverfin.com/f/1499/" +
                companyID +
                "/document_index/" +
                data.folderID}>Gå til {data.type}-mappe</a
            >
          {/if}
        </div>
      </div>
    {/if}
    {#if error}
      <Alert text="Could not get status" />
    {/if}
  </div>
  <div class="footer-wrapper flex-shrink-0 a-p-10 text-center a-bg-content">
    <Footer />
  </div>
</div>

<style>
  .l-head {
    font-weight: 500;
    background-color: var(--theme-color-tablehead);
  }
</style>
