<script>
  export let file;
  export let type;
</script>

<style>
  .appkiticon.icon-circle-save-fill {
    color: var(--theme-color-link);
  }
</style>

{#if file}
  <li class="a-list-item d-table-row">
    <div class="a-h6 d-table-cell a-p-10 text-truncate">{file.name}</div>
    <div class="a-text-9e9 text-right d-table-cell a-p-10 text-truncate">
      {file.id}
    </div>
    <div class="a-text-9e9 d-table-cell text-right a-p-10 text-truncate">
      {#if file.state == 'error'}
        <span class="appkiticon icon-alert-fill" />
        Error
      {:else if file.state == 'pending'}
        Creating file
        <span class="a-loading a-primary a-loading-12" />
      {:else if file.state == 'uploaded'}
        Tilføjet til mappe (<a
          href={file.url}
          target="_blank"
          rel="noopener noreferrer"><span
            class="appkiticon icon-circle-save-fill" />
          Download</a>)
      {:else if file.state == 'created' && type == 'pdf'}
        <a href={file.url} target="_blank" rel="noopener noreferrer"><span
            class="appkiticon icon-circle-save-fill" />
          Download</a>
      {:else if (file.state == 'created' || file.state == 'downloaded') && type == 'penneo'}
        Uploading file
        <span class="a-loading a-primary a-loading-12" />
      {:else if (file.state == 'attached')}
        Success
        <span class="appkiticon icon-check-mark-fill"></span>
      {:else}<span class="appkiticon icon-alert-fill" /> Error{/if}
    </div>
  </li>
{/if}
