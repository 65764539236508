<script>
  import { onMount } from "svelte";
  export let text;
  let show = true;

  onMount(async () => {
    setTimeout(() => {
      show = false;
    }, 20000);
  });
</script>

<style>
  @media screen and (min-width: 36rem) {
    .a-sm-fit {
      width: max-content;
    }
  }
</style>

<div
  class="a-alert alert-default a-alert-fade col-12 a-sm-fit no-gutters error {show ? 'a-alert-show' : 'a-alert-hide'}">
  <div class="a-alert-content">
    <div class="a-alert-badge error">
      <span class="appkiticon icon-alert-fill" />
    </div>
    <span> {text} </span>
  </div>
</div>
